import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { PLATFORM_ID, inject, makeStateKey } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { environment } from 'src/environments/environment';
import { isPlatformServer } from '@angular/common';
import { TransferState } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
export const SKIPLOADING = new HttpContextToken<boolean>(() => false);
const FIRST_LOAD_KEY = makeStateKey<boolean>('firstLoad');
// const excluded_urls = ['addVisitCount', 'addButtonClick'];
export const httpiInterceptor: HttpInterceptorFn = (req, next) => {
  const transfer = inject(TransferState);
  // extend server response observable with logging
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    if (!transfer.hasKey(FIRST_LOAD_KEY)) {
      // Skip HTTP requests when running server-side
      return next(req);
    }
    transfer.set(FIRST_LOAD_KEY, false);
  }
  const loader = inject(LoaderService);
  const isExcluded = req.context.get(SKIPLOADING);

  // const isTranslationRequest = req.url.includes('./assets/i18n/');

  if (!isExcluded) {
    loader.showLoader();
  } else {
  }

  const auth = inject(AuthService);

  const token = auth.getToken();

  if (
    token &&
    !req.url.includes(environment.hdsUrl) &&
    req.url.includes(environment.apiUrl)
  ) {
    // Add Authorization header if token exists and URL is not in hdsUrl domain
    req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
  // setTimeout(() => {
  //   loader.isloading.next(false);
  // }, 200);
  return next(req).pipe(
    tap({
      // Succeeds when there is a response; ignore other events
      next: (event) => {},
      // Operation failed; error is an HttpErrorResponse
      error: (_error) => console.log('request failed', _error),
    }),
    // Log when response observable either completes or errors
    finalize(() => {
      loader.hideLoader();
    })
  );
};
